<template>
  <div>
    <div class="header-container">
      <v-breadcrumbs>
        <v-breadcrumbs-item class="breadcrumbs-hover-default">
          <i class="fal fa-address-card" style="margin: 0 10px 0 0"></i>
          <span @click="main()">{{ $t("use_overtime.title_approve") }}</span>
        </v-breadcrumbs-item>
        <!-- <v-breadcrumbs-item
          class="breadcrumbs-hover"
          v-if="$route.name === 'employee.create'"
        >
          <span v-show="true">/</span>
          <span>{{ $t("Employee.create") }}</span>
        </v-breadcrumbs-item>
        <v-breadcrumbs-item
          class="breadcrumbs-hover"
          v-if="$route.name === 'employee.importData'"
        >
          <span v-show="true">/</span>
          <span>Import</span>
        </v-breadcrumbs-item>
        <v-breadcrumbs-item
          class="breadcrumbs-hover"
          v-if="$route.name === 'employee.edit'"
        >
          <span v-show="true">/</span>
          <span>Edit Employee</span>
        </v-breadcrumbs-item> -->
      </v-breadcrumbs>
    </div>
    <div class="page-content">
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },

  methods: {
    main() {
      this.$router
        .push({
          name: "employee_approve_use_ot.index",
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.content ul {
  list-style: disc outside;
  margin-left: 0.5em !important;
  margin-top: 1em;
}
</style>
